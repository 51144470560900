import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/Layout';
import Arrow from '../svg/arrow.svg';
import Seo from '../components/Seo';

const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

const Service = ({ pageContext }) => {
  const { order, title, text, prev, next, name } = pageContext;
  const content = JSON.parse(text.text);

  return (
    <Layout>
      <Seo title={name} description={title} />
      <section className="Service">
        <section className="Service__hero">
          <div className="Service__hero__number">
            <span>{order + 1}</span>
          </div>
          <h1>{title}</h1>
          <footer className="Service__hero__footer">
            <AniLink swipe top="exit" direction="left" to={`/${prev}`}>
              <Arrow />
              <span>Précédent</span>
            </AniLink>
            <AniLink swipe top="exit" direction="right" to={`/${next}`}>
              <span>Suivant</span>
              <Arrow />
            </AniLink>
          </footer>
        </section>
        <section className="Service__content wrapper container">
          <div>{documentToReactComponents(content, options)}</div>
          {order === 0 ? (
            <div className="Service__content__pack">
              <div className="Service__content__pack__item">
                <h3>Consult & Save</h3>
                <h4>
                  Vous rencontrez des
                  <br />
                  problèmes juridiques ?
                </h4>
                <a
                  href="https://www.comptaplan.be/services/consult-save"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Découvrir
                </a>
              </div>
              <div className="Service__content__pack__item">
                <h3>Contrôle Fiscal</h3>
                <h4>
                  Le fisc vous pourchasse,
                  <br />
                  les contrôles s’intensifient !
                </h4>
                <a
                  href="https://www.comptaplan.be/services/controle-fiscal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary"
                >
                  Découvrir
                </a>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </section>
    </Layout>
  );
};

export default Service;
